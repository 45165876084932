'use client';

import React from 'react';
import { useTranslations } from 'next-intl';

import { Alert } from '@zealy/design-system';
import { useAuthenticatedUser, User } from '@zealy/queries';

import { useCommunitiesFlags } from '#context/FeatureFlags';

export const CheatRestrictedBanner = () => {
  const t = useTranslations('settings.cheat-alert');
  const user = useAuthenticatedUser<User>();

  if (user.data?.cheatStatus !== 'restricted') {
    return null;
  }

  return (
    <Alert
      title={t('title')}
      description={t('restricted', {
        restrictedUntil: user.data.restrictedUntil ? new Date(user.data.restrictedUntil) : '',
      })}
      variant="warning"
      orientation="horizontal"
    />
  );
};
